import { render, staticRenderFns } from "./PageSettleDashboard.vue?vue&type=template&id=a48da054&scoped=true&"
import script from "./PageSettleDashboard.vue?vue&type=script&lang=js&"
export * from "./PageSettleDashboard.vue?vue&type=script&lang=js&"
import style0 from "./PageSettleDashboard.vue?vue&type=style&index=0&id=a48da054&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a48da054",
  null
  
)

export default component.exports